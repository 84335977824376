import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import Loader from "../components/Loader";
import Languages from "../components/Languages";
import API from "../services/api";

export default class Rate extends Component {
  constructor(props) {
    super(props);

    let language = localStorage.getItem("language");

    this.state = {
      isLoading: true,
      page: null,
      me: null,
      lang: null,
      language: ["en", "cz", "hr", "hu", "ru", "sk", "si"].indexOf(language)
        ? language
        : "en",
      languages: [],
      survey: null,
      surveyId: this.props.match.params.id,
      isDone: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    let survey;

    await API.get("", {
      params: {
        action: "me",
      },
    })
      .then((response) => {
        this.setState({
          me: response.data,
        });
      })
      .catch((error) => {});

    await API.get("", {
      params: {
        action: "languages",
      },
    })
      .then((response) => {
        let data = response.data;
        let currentLanguage = this.state.language;
        let languageFound = false;

        for (let i in data) {
          if (data[i].id === currentLanguage) {
            languageFound = true;
          }
        }

        if (!languageFound) {
          localStorage.setItem("language", data[0].id);
          window.location.reload();
          return false;
        }

        this.setState({
          languages: data,
        });
      })
      .catch((error) => {});

    await API.get("", {
      params: {
        action: "survey",
        id: this.state.surveyId,
        language: this._getCurrentLanguage(),
      },
    })
      .then((response) => {
        survey = response.data;

        this.setState({
          survey: survey,
        });
      })
      .catch((error) => {});

    await API.get("", {
      params: {
        action: "home",
        language: this.props.getLanguage(),
      },
    })
      .then((response) => {
        this.setState({
          page: response.data,
        });
      })
      .catch((error) => {});

    await API.get("", {
      params: {
        action: "translations",
        slug: "rate",
        language: this.props.getLanguage(),
      },
    })
      .then((response) => {
        this.setState({
          t: response.data,
        });
      })
      .catch((error) => {});

    this.setState({
      isLoading: false,
    });
  };

  _inputOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  _answerOnChange = async (event) => {
    let answer = parseInt(event.target.dataset.answer, 10);
    let question = parseInt(event.target.dataset.question, 10);
    let survey = this.state.survey;

    for (let i in survey.questions) {
      if (survey.questions[i].id === question) {
        survey.questions[i].answer = answer;
        survey.questions[i].error = false;
        break;
      }
    }

    this.setState({
      survey: survey,
    });

    await API.post("", {
      action: "surveyAnswer",
      surveyId: this.state.surveyId,
      question: question,
      answer: answer,
    })
      .then((response) => {})
      .catch((error) => {});
  };

  _noCommentOnChange = async (event) => {
    let question = parseInt(event.target.dataset.question, 10);
    let survey = this.state.survey;

    for (let i in survey.textQuestions) {
      if (survey.textQuestions[i].id === question) {
        survey.textQuestions[i].answer = "";
        survey.textQuestions[i].error = false;
        survey.textQuestions[i].noComment = event.target.checked;
        break;
      }
    }

    this.setState({
      survey: survey,
    });

    await this._saveTextAnswer(question, "");
  };

  _textOnChange = async (event) => {
    let answer = event.target.value;
    let question = parseInt(event.target.dataset.question, 10);
    let survey = this.state.survey;

    for (let i in survey.textQuestions) {
      if (survey.textQuestions[i].id === question) {
        survey.textQuestions[i].answer = answer;
        survey.textQuestions[i].error = false;
        break;
      }
    }

    this.setState({
      survey: survey,
    });
  };

  _textOnBlur = async (event) => {
    let answer = event.target.value;
    let question = parseInt(event.target.dataset.question, 10);

    await this._saveTextAnswer(question, answer);
  };

  _saveTextAnswer = async (question, answer) => {
    await API.post("", {
      action: "surveyTextAnswer",
      surveyId: this.state.surveyId,
      question: question,
      answer: answer,
    })
      .then((response) => {})
      .catch((error) => {});
  };

  _onSubmit = async (event) => {
    let survey = this.state.survey;
    let isDone = true;

    for (let i in survey.questions) {
      if (survey.questions[i].answer === null) {
        survey.questions[i].error = true;
        isDone = false;
      }
    }

    for (let i in survey.textQuestions) {
      if (survey.textQuestions[i].answer === null) {
        survey.textQuestions[i].error = true;
        isDone = false;
      }
    }

    this.setState({
      survey: survey,
      isDone: isDone,
    });
  };

  _getCurrentLanguage = () => {
    return this._getLanguage(localStorage.getItem("language"));
  };

  _getLanguage = (id) => {
    let languages = this.state.languages;

    for (let i in languages) {
      if (languages[i].id === id) {
        return languages[i].id;
      }
    }

    return languages[0].id;
  };
  _t = (key) => {
    return this.state.t[key];
  };

  render() {
    const { isLoading, isDone, survey, t } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isDone) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        <Languages />
        <div className="back">
          <NavLink to="/">&laquo; {t.generic_back}</NavLink>
        </div>
        <div className="survey__info">
          {t.rate_survey_for} <strong>{survey.user}</strong>
        </div>
        {survey.description && (
          <div
            className="survey__description"
            dangerouslySetInnerHTML={{ __html: survey.description }}
          />
        )}
        <div className="page__content survey">
          {survey.questions.map((item, key) => (
            <div
              key={key}
              className={`survey__question${
                item.answer !== null ? " survey__question--completed" : ""
              }${item.error === true ? " survey__question--error" : ""}`}
            >
              <div>
                <strong>
                  {key + 1}. {item.content}
                </strong>
              </div>
              <div className="survey__question__options">
                {survey.answers.map((answer, key) => (
                  <label key={key} className="survey__answer">
                    <input
                      type="radio"
                      name={`question_${item.id}`}
                      value={answer.id}
                      checked={item.answer === answer.id}
                      onChange={this._answerOnChange}
                      data-question={item.id}
                      data-answer={answer.id}
                    />{" "}
                    {answer.content}
                  </label>
                ))}
              </div>
            </div>
          ))}
          {survey.textQuestions.map((item, key) => (
            <div
              key={key}
              className={`survey__question${
                item.answer || item.noComment === true
                  ? " survey__question--completed"
                  : ""
              }${item.error === true ? " survey__question--error" : ""}`}
            >
              <div>
                <strong>
                  {key + 1}. {item.content}
                </strong>
              </div>
              <div className="survey__question__input">
                {survey.relation_type !== "satisfaction" && (
                  <p className="error">{t.rate_text_input_info}</p>
                )}
                <textarea
                  name={`question_${item.id}`}
                  className="input input--area"
                  readOnly={item.noComment === true}
                  onChange={this._textOnChange}
                  onBlur={this._textOnBlur}
                  data-question={item.id}
                  value={item.answer !== null ? item.answer : ""}
                />
              </div>
              <div className="survey__question__input__check">
                <label>
                  <input
                    type="checkbox"
                    name={`question_check_${item.id}`}
                    data-question={item.id}
                    onChange={this._noCommentOnChange}
                  />
                  {t.rate_text_input_check}
                </label>
              </div>
            </div>
          ))}
          <div className="survey__submit">
            <button className="button button--large" onClick={this._onSubmit}>
              {t.generic_submit}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
